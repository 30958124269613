import { MSL_API_URL } from 'environment';

export async function getCustomersAPI(token, query) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan${query}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function getCustomerDetailAPI(token, id) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function addCustomerAPI(token, customerForm) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({ ...customerForm });

  const requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateCustomerAPI(token, id, customerForm) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({ ...customerForm });

  const requestOptions = { method: 'PUT', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function verificationIdentityCustomerAPI(token, id) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'PUT', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/verifikasi-ktp/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateCustomerFileAPI(token, id, file) {
  const myHeaders = new Headers();
  myHeaders.append('Accept', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const formData = new FormData();
  formData.append('identity_file', file);
  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: formData,
    redirect: 'follow',
  };

  return fetch(`${MSL_API_URL}/admin/pelanggan/file/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function removeCustomerAPI(token, id) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'DELETE', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function getFormOptionCustomerAPI(token) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/opsi/form`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function addSellerFeeAPI(token, body) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify(body);

  const requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/biaya-seller`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateSellerFeeAPI(token, id, body) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify(body);

  const requestOptions = { method: 'PUT', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/biaya-seller/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateMOUFileAPI(token, id, mou_file) {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  var formdata = new FormData();
  formdata.append('mou_file', mou_file);

  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow',
  };

  return fetch(`${MSL_API_URL}/admin/pelanggan/biaya-seller/file/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateBuyerFeeAPI(token, id, body) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify(body);

  const requestOptions = { method: 'PUT', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/biaya-pembeli/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function getCustomerInventoriesAPI(token, id, query) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/unit/${id}${query}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function addCustomerInventoryAPI(token, inventoryForm) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({ ...inventoryForm });

  const requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/unit`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateCustomerInventoryAPI(token, id, inventoryForm) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({ ...inventoryForm });

  const requestOptions = { method: 'PUT', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/unit/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function removeCustomerInventoryAPI(token, id) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({
    ids: [id],
  });

  const requestOptions = { method: 'DELETE', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/unit`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function getNiplAuctionAPI(token, id, query) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/riwayat-nipl/${id}${query}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function getHistoryAuctionAPI(token, id, query) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pelanggan/riwayat-lelang/${id}${query}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}
